:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6000;
  --backgroung: #0e192f;
}

* {
  box-sizing: border-box;
}

.bg-slide {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  color: white;
}

.bg-slide .items {
  padding-right: 70px;
  position: relative;
  /* background-color: red; */
  width: 60% !important;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 99999;
}
.bg-slide .items::before {
  content: "";
  position: absolute;
  width: 150%;
  height: 100%;
  background: rgba(0, 0, 0, 0.434);
  z-index: -1;
  left: 10px;
  height: 150%;
  transform: rotate(345deg);
  border-left: 5px solid;
  border-image: linear-gradient(209deg,
      var(--main-orange) 0%, var(--main-orange) 70%,
      var(--main-blue) 70%, var(--main-blue) 100%) 1;
}



.bg0 {
  background-image: url(../image/ramadan.png);
}
.bg1 {
  background-image: url(../image/7.png);
}
.bg2 {
  background-image: url(../image/12.png);
}
.bg3 {
  background-image: url(../image/11.png);
}
.bg4 {
  background-image: url(../image/14.png);
}
.bg5 {
  background-image: url(../image/12.png);
}
.bg6 {
  background-image: url(../image/13.png);
}

.SwiperSlide h1 {
  color: white;
  font-weight: bold;
  font-size: 60px;
  text-shadow: -5px 6px 9px #000000a3;
  cursor: default;
  line-height: 1.5;
}

.SwiperSlide h1 span {
  color: var(--main-orange);
}

.bg-slide p {
  color: white;
  text-shadow: -5px 6px 9px #000000a3;
  font-size: 25px;
  line-height: 1.8;
  padding-right: 30px;
  padding-top: 20px;
  font-weight: bold;
  cursor: default;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--main-orange);
}

.swiper-pagination-bullet-active {
  background-color: var(--main-blue);
}


.SwiperSlide button {
  background-color: var(--main-blue);
  margin-right: auto;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
  margin-right: 50px;
  box-shadow: -5px 6px 9px 0px #000000a3;
  border: unset;
  font-weight: bold;
}

.SwiperSlide button:hover {
  background-color: var(--main-orange);
  color: #0bbde9;
}

.mySwiper {
  height: 115vh;
}

.mySwiper .items-bottom {
  z-index: 99999999999;
  left: 70%;
  right: 70%;
  height: 100px;
}

@media (max-width: 800px) {
  .swiper  h1 {
    font-size: 35px;
  }

  .swiper p {
    font-size: 20px;
    padding-right: 15px;
  }
  
  .bg-slide .items {
    padding-right: 10px;
    position: relative;
    width: 75% !important;
  }

  .bg-slide .items .box-data {
    position: absolute;
    top: 20%;
  }

  .SwiperSlide button {
    margin-right: 15px;
    margin-top: 10px;
  }
}


:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6000;
  --backgroung: #0e192f;
}

.app {
  background-image: url(../image/services-left-dec.png);
  background-repeat: no-repeat;
  /* height: 100vh; */
  padding-top: 50px !important;
}

.bs-apps {
  background-image: url(../image/19199364.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
}

.app .container p , .app .container h2 {
  text-align: start;
  line-height: 1.5;
}

.app h2 {
  color: var(--main-orange);
  font-weight: bolder;
  cursor: default;
  line-height: 1.5;
}

.app ul li {
  list-style: none;
  cursor: default;
}

.bg-app {
  /* background-image: url(../image/19199364.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 150px;
}

.butom-app {
  text-align: right;
  margin-right: 80px;
}

.app a {
  color: white;
  background-color: var(--main-blue);
  font-size: 20px;
  text-align: start;
  transition: .5s all !important;
  -webkit-transition: .5s all !important;
  -moz-transition: .5s all !important;
  -ms-transition: .5s all !important;
  -o-transition: .5s all !important;
}

.app a:hover {
  background-color: var(--main-orange) !important;
}

@media (max-width: 1250px) { 
  .bs-apps {
    background-image: unset ;
  }

  .support-text {
    width: 100% !important;
  }
}
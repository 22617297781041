:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6100;
  --backgroung: #0e192f;
}


.navbar {
  transition: 1s all;
  -webkit-transition: 1s all;
  -moz-transition: 1s all;
  -ms-transition: 1s all;
  -o-transition: 1s all;
  z-index: 9999999999999;
}

.navbar .container-fluid {
  width: 70%;
}

.navbar ul li {
  font-size: 20px;
  margin-top: 0;
  margin-right: 10px;
}

.navbar-brand img {
  width: 80px;
}

.navbar .active {
  position: relative;
}

.navbar-brand {
  font-size: 1.5rem !important;
  font-weight: bolder;
}

.nav-link {
  color: white;
}

.navbar .active::after {
  content: attr(data-text);
  color: var(--main-orange);
  position: absolute;
  top: 0;
  right: 0px;
  padding: 0.5rem;
}

.navbar .active::before {
  content: '';
  position: absolute;
  margin: auto;
  left: 0%;
  right: 0%;
  height: 2px;
  background-color: var(--main-orange);
  top: 110%;
  animation: activeborder 0.8s ease-in-out alternate forwards;
  -webkit-animation: activeborder 0.8s ease-in-out alternate forwards;
}

@keyframes activeborder {

  0% {
    left: 100%;
    right: 0%;
  }

  100% {
    left: 0%;
    right: 0%;
  }

}

.icons-Nav {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    text-decoration: none;
  }
  

  .icons-Nav li {
    color: white;
    width: fit-content;
  }

  .icons-Nav li a , .icons li span , .icons li a {
    color: white;
  }
  .nav-item {
    list-style: none;
  }
  .icons li a:hover ,.icons li a:focus {
    color: white;
  }



@media (min-width: 200px) and (max-width: 992.98px) {

  .navbar {
    background-color: #131722b8 !important;
    margin-bottom: 10px !important;
  }
  .navbar .container-fluid {
    width: 100%;
  }
  .icons-Nav {
    display: flex;
    justify-content: center;
    list-style-type: none;
    text-decoration: none;
  }
  .icons-Nav li {
    color: white;
    width: fit-content;
    padding: 1rem .3rem;
  }
  .icons-Nav li a {
    color: white;
  }
  .icons-Nav .nav-item{
    display: block;
  }

  .navbar .active {
  margin-right: 10px;
  transition: .5s all;

  }

  .active::after {
    padding: 0.5rem 0;
    right: 10px;
  }
  .navbar-brand img {
    width: 60px;
  }
  .navbar .active::after {
    padding: 0.5rem 0 !important;
  }
  .navbar-toggler {
    border: unset;
  }
  .navbar-toggler-icon {
    background-image: unset;
    font-size: 25px;
    color: white;
    padding: 10px;
    /* border-radius: 5px;
    border: 2px solid white;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px; */
}
}



:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6000;
  --backgroung: #0e192f;
}


/* .ramadan {
  padding: 10px;
  background-color: #151d2c;
} */

.ramadan .item {

  padding: 10px 5px;
}


.ramadan .box p {
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,0.28904061624649857) 0%, rgba(14,25,47,1) 54%, rgba(14,25,47,1) 60%, rgba(14,25,47,1) 67%);
  line-height: 1.8;
  opacity: 0;
  transition: .5s all;
  -webkit-transition: .5s all;
  -moz-transition: .5s all;
  -ms-transition: .5s all;
  -o-transition: .5s all;
}

.ramadan .item:hover p {
  opacity: 1;
}


.in-search {
  margin: auto;
  outline: unset;
  border-radius: 80px;
  transition: .5s all;
  border: unset;
}

.in-search:focus {
  box-shadow: 2px 2px 2px 2px var(--main-orange);
  font-size: 20px;
}

.ramadan .type {
  background: var(--main-orange);
  left: 0px;
  top: 0px;
  padding: 8px;
  border-bottom-right-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
}


.pearent {
  overflow-x: hidden;
}


.spinner-grow {
  background-color: var(--main-orange) !important;
}

.category_name {
  color: var(--main-orange);
}

.box img {
  width: 230px;
  height: 300px;
}
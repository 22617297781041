

.WhatsApp {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 99999999999999999999999999999999999;
}

.telegram {
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 99999999999999999999999999999999999;
}


.styles-module_chatHeader__gaeOA .styles-module_avatar__afcc4 img {
  border: unset;
}
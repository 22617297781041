:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6000;
  --backgroung: #0e192f;
}

.counter-data {
  margin-top: 50px;
  position: relative;
}

.counter-data .wave-top {
  position: absolute;
  top: 0;
  width: 100%;
  right: 0;
}

.media {
  width: 100%;
  min-height: 700px;
  padding: 50px;
  background-image: url(../image/4.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  position: relative;
}

.container-media {
  background-color: #0e192fa3;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.container-media-item {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-media .item {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.container-media svg {
  color: white;
  font-size: 10px;
  border: 15px solid rgba(255, 255, 255, 0.586);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 20px;
  width: 60px;
  height: 60px;
}

.container-media h4 {
  color: var(--main-orange);
}
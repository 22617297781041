:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6000;
  --backgroung: #0e192f;
}

.price {
  /* height: 50vh; */
  background-image: url(../image/portfolio-left-dec.png);
  background-repeat: no-repeat;
  background-position: center left;
  position: relative;
  margin-top: 200px;
}

.price h2 {
  color: var(--main-orange);
  font-weight: bolder;
  cursor: default;
}

.price .tabContent ul .nav-item {
  width: fit-content;
}

.price-box .item {
  transform: scale(0.9) !important;
  -webkit-transform: scale(0.9) !important;
  -moz-transform: scale(0.9) !important;
  -ms-transform: scale(0.9) !important;
  -o-transform: scale(0.9) !important;
}
.price-box .item:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  -o-transform: scale(1) !important;
}

.tabContent {
  width: 100%;
  /* height: 200px; */
}

.tab-price .item {
  background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
  background-color: var(--backgroung);
  position: relative;
  overflow: hidden;
  border-right: 3px solid var(--main-orange);
  border-bottom: 3px solid var(--main-orange);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}

.tabContent .item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: url(../image/services-left-dec.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
  z-index: -1;


}

.tabContent .item h2 {
  color: white;
  font-weight: bold;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  cursor: default;
}

.tabContent .item:hover h2 {
  color: var(--main-orange);
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.box-price {
  background-color: var(--main-orange);
  padding: 10px;
  width: 65%;
  transform: translateX(7%) !important;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  -webkit-transform: translateX(7%) !important;
  -moz-transform: translateX(7%) !important;
  -ms-transform: translateX(7%) !important;
  -o-transform: translateX(7%) !important;
}

.box-price h4 {
  font-size: 30px;
  color: white;
  font-weight: bold;
  cursor: default;
  margin-right: 20px;
  font-size: 40px;
}

.tab-pane .item:hover .price h2 {
  color: var(--main-orange);
}

.box-price h5 {
  font-weight: bold;
  color: white;
  cursor: default;
  font-size: 20px;
}

.tabContent .container {
  position: relative;
  padding-bottom: 50px;
}

.tabContent .container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0px;
  background-image: url(../image/services-right-dec.png);
  background-repeat: no-repeat;
  background-position: right bottom;
}

.tabContent ul li {
  color: wheat;
  list-style: none;
  font-weight: bold;
  transition: 0.3s all !important;
  -webkit-transition: 0.3s all !important;
  -moz-transition: 0.3s all !important;
  -ms-transition: 0.3s all !important;
  -o-transition: 0.3s all !important;
  cursor: default;
}

.year .item ul li:hover {
  color: var(--main-orange) !important;
  margin-right: 10px !important;
}

.tabContent ul li span {
  border: unset;
}

.tabContent button {
  color: white;
  background-color: var(--main-blue);
  text-align: center;
  transform: translateX(-80%);
  -webkit-transform: translateX(-80%);
  -moz-transform: translateX(-80%);
  -ms-transform: translateX(-80%);
  -o-transform: translateX(-80%);
  margin-top: 30px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
  font-weight: bold;
}

.tabContent button:hover {
  color: white;
  background-color: var(--main-orange);
}

.price .nav-item .nav-link {
  color: white;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  border: unset;
}


.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #fe6000;
}

.price .nav-item .nav-link:hover {
  background-color: var(--main-orange);
  transform: scale(1);
}



.tabContent>.active:focus-visible {
  border: unset;
  outline: unset;
}
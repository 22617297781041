
:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6000;
  --backgroung: #0e192f;
}

.heder-support h2 {
  color: var(--main-orange);
  font-weight: bold;
}

.Technical {
  margin-top: 100px;
  width: 100%;
  padding-top: 50px;
  /* overflow-x: hidden !important; */
}

.Technical .content {
  width: 90%;
  margin: auto;
}

.accordion-button {
  color: white;
  background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
}

.accordion-button:not(.collapsed) {
  color: var(--main-orange) !important;
  font-weight: bold;
  /* margin-right: 10px; */
}

.Technical .content ul li {
  list-style-type: none;
  padding: 5px;
  margin: 0;
}

.Technical .content button {
  color: white;
  background-color: var(--main-blue);
  transition: .5s all;
  -webkit-transition: .5s all;
  -moz-transition: .5s all;
  -ms-transition: .5s all;
  -o-transition: .5s all;
}
.Technical .content button:hover {
  color: white !important;
  background-color: var(--main-orange);
}


.btn-support a:hover {
  color: white;
}

.Technical .content button:focus {
  box-shadow: unset;
}

.accordion-item {
  border: 1px solid var(--backgroung);
}

.Technical .bg-content-right {
  background-image: url(../image/services-right-dec.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  padding-bottom: 50px;
}
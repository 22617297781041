:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6000;
  --backgroung: #0e192f;
}


.count-items {
  background-color: #0e192f;
  text-align: center;
  position: relative;
}

.count-items::before {
  content: "";
  position: absolute;
  width: 100%;
  background: linear-gradient(    180deg,    rgba(2, 0, 36, 0.28904061624649857) 0%,    rgba(14, 25, 47, 1) 54%,    rgba(14, 25, 47, 1) 60%,    rgba(14, 25, 47, 1) 67%  );
  right: 0;
  transform: translateY(-50%);
  z-index: 99;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.count-items .items {
  width: 60%;
  /* height: 200px; */
  padding: 20px;
  margin: auto;
  background-color: var(--backgroung);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  z-index: 99;
  position: relative;
  box-shadow: -7px 5px 13px -1px #000000a8;
  border: 3px solid;
  border-color: var(--main-blue) var(--main-blue) var(--main-orange) var(--main-orange);
  /* border-image: linear-gradient(14deg,      #fe6000 0%, #fe6000 70%,      #0bbde9 70%, #0bbde9 100%) 4; */
  border-radius: 80px;
  -webkit-border-radius: 80px;
  -moz-border-radius: 80px;
  -ms-border-radius: 80px;
  -o-border-radius: 80px;
  background-image: url(../image/footer-dec.png);
  /* border-width: 5px; */
  /* border-color: #fe6000; */
  /* border-image: linear-gradient(14deg,      #fe6000 0%, #fe6000 70%,      #0bbde9 70%, #0bbde9 100%) 4;  */
  /* border-image-slice: 150 75; */
}

.count-items svg {
  font-size: 60px;
  color: var(--main-orange);
}

.count-items h3 {
  color: white;
  margin-top: 10px;
}

.channel div {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}


@media (max-width: 1250px) { 
  .count-items .items {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    z-index: 99;
}

.bein , .ad , .ssc , .osn {
  margin-top: 20px;
}
}
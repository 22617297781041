
:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6000;
  --backgroung: #0e192f;
}

.Down-app {
  margin-top: 100px;
}


.smart-tv-list h3 {
  color: var(--main-orange);
  font-weight: bold;
  line-height: 1.5;
}

a .bottom-down {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: var(--backgroung);
  background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
  padding: 20px;
  text-align: end;
  border-radius: 10px;
  display: inline-block;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: 2s all !important;
  -webkit-transition: 2s all !important;
  -moz-transition: 2s all !important;
  -ms-transition: 2s all !important;
  -o-transition: 2s all !important;
}

a .bottom-down:hover {
  background: linear-gradient(90deg, #fe6000 0%, #bc4a04d1 100%);
}

a .bottom-down  {
  background-color: unset;
}
a .bottom-down a:hover {
  background-color: unset;
}


.ios , .windows {
  width: fit-content;
}

.smart-tv {
  color: var(--main-orange);
  font-weight: bold;
  cursor: default;
  line-height: 1.5;

}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border: unset;
  background: linear-gradient(90deg, #fe6000 0%, #bc4a04d1 100%);
  border-top-left-radius: 0 !important;
}

.nav-tabs .nav-link {
  color: white;
  background-color: var(--backgroung);
  background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
  font-weight: bold;
}

.smart-tv-list ul li {
  counter-increment: count;
  position: relative;
  cursor: default;
}


.smart-tv-list ul li::before {
  content: counter(count) " - ";
  position: absolute;
  right: -20px;
}
:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6000;
  --backgroung: #0e192f;
}


.support {
  background-image: url(../image//services-right-dec.png);
  background-repeat: no-repeat;
  padding-right: 20px;
  background-position: left bottom;
  position: relative;
  padding-bottom: 300px;
}

.support .wave {
  width: 100%;
  height: 100px;
  /* background-color: #0e192f; */
  position: absolute;
  left: 0;
  bottom: -120px;
  z-index: 9999;
}

.support h2,
.support p,
ul li {
  text-align: start;
  margin-top: 20px;
}

.support h2 {
  color: var(--main-orange);
  line-height: 1.5;
}
.support p {
  line-height: 1.9;
  font-size: 17px;
}

.support-text {
  background-image: url(../image/services-left-dec.png);
  background-repeat: no-repeat;
  background-position: right;
}

.support ul {
  list-style-type: none;
  margin: 0;
  margin-top: 50px;
  padding: 0;
}

ul li span {
  color: var(--main-orange);
  border: var(--main-orange) 2px solid;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.img-support {
  background-image: url(../image/support.png);
  background-position: 30% 80%;
  background-repeat: no-repeat;
  background-size: cover;
}
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Almarai', sans-serif;
  /* font-weight: 300; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



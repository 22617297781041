:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6000;
  --backgroung: #0e192f;
}

body {
  direction: rtl;
  color: white;
  background-color: #0e192f;
  overflow-x: hidden !important;
  position: relative;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #0c2738;
}

::-webkit-scrollbar-thumb {
  background-color: #fe6100b7;
  border-radius: 20px;
  transition: 1s all;
  -webkit-transition: 1s all;
  -moz-transition: 1s all;
  -ms-transition: 1s all;
  -o-transition: 1s all;
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--main-orange);
    transition: 3s all;
    -webkit-transition: 3s all;
    -moz-transition: 3s all;
    -ms-transition: 3s all;
    -o-transition: 3s all;
}
::-webkit-scrollbar-thumb:hover ::-webkit-scrollbar {
  width: 20px;
}


.review h1 {
  color: var(--main-orange)
}
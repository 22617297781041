:root {
  --main-blue: #0bbde9;
  --main-orange: #fe6100;
  --backgroung: #0e192f;
}

.footer {
  background-color: var(--backgroung);
  border-top: 2px solid var(--main-orange);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer h5 {
  cursor: default;
}

.footer a {
  color: white;
  font-weight: bold;
  text-decoration: none;
  transition: .5s all;
  -webkit-transition: .5s all;
  -moz-transition: .5s all;
  -ms-transition: .5s all;
  -o-transition: .5s all;
}

.footer a:hover {
  color: var(--main-orange);
}